// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /* stripeKey: 'pk_live_51MSPFXDUHPj6zCzkxwV3yPrU7GEN5DR8FmIwk1ii1STb5M1n7bJnKunLgg9MssEzRuqBaztQXbR2M3F8xeU1b8eJ00vacSE8oS', */
  stripeKey: 'pk_test_51MdwEaHtysA9QBWqVGh1HTuJFSexXFYD5PcFOZYMMwZQwPQssPQ6QBJWhXc7sICG7xYfuAaUfAleqwGswJaAAhdX00g2XEL7Rq',
  /* firebase: {
    apiKey: 'AIzaSyCgmT8WMY-aVOCt8bGO59Gv1-EglDrMGC4',
    authDomain: 'appionic-2fd3c.firebaseapp.com',
    databaseURL: "https://appionic-2fd3c-default-rtdb.firebaseio.com",
    projectId: 'appionic-2fd3c',
    storageBucket: 'appionic-2fd3c.appspot.com',
    messagingSenderId: '659233727865',
    appId: '1:659233727865:web:e879e76f2f666199e7fba8',
    measurementId: 'G-KS2K414R67'
        }, */
  firebase: {
    apiKey: 'AIzaSyAYUL4cn2l1KuzaBl4rakBqdChmIFN7loc',
    authDomain: 'appionic-3f8fd.firebaseapp.com',
    projectId: 'appionic-3f8fd',
    storageBucket: 'appionic-3f8fd.appspot.com',
    messagingSenderId: '856701607404',
    appId: '1:856701607404:web:ab94d18d35a85889a274c9',
    measurementId: 'G-0BKWWFZFV0'
        },
    URL:'',
    nombre_img:'80',
    idioma:'en',
    /* idioma:'en', */
    altura_img:'350px',
    img_404:'https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png',
    /* urlStatic: 'https://swedishknickers.com/app_laravel/public', */
    /* path_prod: 'http://localhost:8000',
    time_idle: '3600',
    
    socks: '../../../assets/img/socks.jpg',
    bras: '../../../assets/img/socks.jpg',
    lingerie: '../../../assets/img/socks.jpg',
    panties: '../../../assets/img/socks.jpg',
    toys: '../../../assets/img/socks.jpg',
    costumes: '../../../assets/img/socks.jpg',
    swimwear: '../../../assets/img/socks.jpg',
    nightwear: '../../../assets/img/socks.jpg',
    otros: '../../../assets/img/socks.jpg', */

    socks: '../../../assets/img/socks_.webp',
    bras: '../../../assets/img/bras_.webp',
    lingerie: '../../../assets/img/lingerie_.webp',
    panties: '../../../assets/img/panties_.webp',
    toys: '../../../assets/img/toys_.webp',
    costumes: '../../../assets/img/costumes_.webp',
    swimwear: '../../../assets/img/swimwear_.webp',
    nightwear: '../../../assets/img/nightwear_.webp',
    otros: '../../../assets/img/otros_.webp',

    time_idle: '3600',
    // Producción
    /* path_prod: 'https://swedishknickers.com/app_laravel/public' */

    // Local
    //path_prod: 'http://127.0.0.1:8000',

    // test

    path_prod: 'https://americanknickers.com/app_laravel/public'
    
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
