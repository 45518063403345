import { Component, OnInit, Inject, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { skServices } from 'src/app/services/sk.services.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { FormMsgComponent } from '../form-msg/form-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { RouterService } from 'src/app/services/router.service';
import { FormRegistroMiembroComponent } from '../form-registro-miembro/form-registro-miembro.component';
import { FormCookiesComponent } from '../form-cookies/form-cookies.component';
import { FormRecuperacionComponent } from '../form-recuperacion/form-recuperacion.component';

@Component({
  selector: 'app-form-login-usuarios',
  templateUrl: './form-login-usuarios.component.html',
  styleUrls: ['./form-login-usuarios.component.scss'],
})
export class FormLoginUsuariosComponent implements OnInit {
  @ViewChild('passwordEyeRegister, { read: ElementRef }') passwordEye: ElementRef;
  @Input() data: any;
  passwordTypeInput  =  'password';
  emailRegex:any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  telRegex:any = /\d{4}-\d{6}\d/;
  Regex:any = /^[J,V]{1}\d{9}/;
  hide:any = true;
nombre:any;
forma:FormGroup;
dialogSpin:any;
accion:any;
unidad:any;
lg:any = localStorage.getItem('lg');
listas:any = [];
tipo_cliente = [];
deposito_pt = [];
almacen:any = [];
almacen_cdn:any = [];
almacen_dstrbdr:any = [];
almacen_pvp:any = [];
tipo_um_und = [];
tipo_um_und_consig = [];
asociar:any = 'N/A';
actualizar:any = false;
  constructor(
    private fb:FormBuilder,
    private skservice: skServices,
    private loadindControl: LoadingController,
    private util: UiKitController,
    private modalController: ModalController,
    private route_app: RouterService,
    ) {
      this.forma = this.fb.group({
        correo: ['', [Validators.required, Validators.email]],
        pass: ['', [Validators.required]],
        tipo: this.data,
      });
    }

  ngOnInit(): void {
  }

async  togglePasswordMode() {
    //cambiar tipo input
  this.passwordTypeInput = this.passwordTypeInput === 'text' ? 'password' : 'text';
   //obtener el input
   const nativeEl = this.passwordEye.nativeElement.querySelector('input');
   //obtener el indice de la posición del texto actual en el input
   const inputSelection = nativeEl.selectionStart;
   //ejecuto el focus al input
   nativeEl.focus();
  //espero un milisegundo y actualizo la posición del indice del texto
   setTimeout(() => {
       nativeEl.setSelectionRange(inputSelection, inputSelection);
   }, 1);

}

 async modalrespuesta(){
  console.log ('this data', this.data);
    try {
      this.forma.value.tipo = this.data;
    await this.util.cargando();
    this.modalController.dismiss(this.forma.value);
    this.forma.reset();
    await this.util.closeLoading();
    } catch (error) {
      console.log(error);
    }
  }
  async dismissModal() {
    await this.util.loading();
    this.modalController.dismiss();
  }
  async tienda_app_param() {
    try {
      await this.util.cargando();
      var xvalor = {
        correo:this.forma.value.correo
      }
      const response = await this.skservice.getCorreoValidohttp(xvalor).toPromise();
            this.forma.reset();
        const modal = await this.modalController.create({
          component: FormRecuperacionComponent,
          cssClass: 'my-custom-class',
          componentProps: { data:xvalor},
          backdropDismiss: false
        });
        await modal.present();
        await this.util.closeLoading();
        const { data } = await modal.onWillDismiss()
    } catch (error) {
      await this.util.closeLoading();
      if (this.lg.toLowerCase() === 'en') {
        await this.util.ToastWarning('The email address does not exist in our database');
      }
      if (this.lg.toLowerCase() === 'al') {
        await this.util.ToastWarning('Die E-Mail-Adresse existiert nicht in unserer Datenbank');
      }
      console.log(error);
    }
  }
}
