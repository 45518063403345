
import * as _ from 'lodash';
//NG-OFFLINE
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, fromEvent, merge, empty, Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { mapTo } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ref, Storage, uploadBytes } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

type Usuario = {cod_usuario: string, des_usuario: string}

@Injectable({
    providedIn: 'root'
})
export class skServices {
    ruta: any;
    CARPETA_IMAGENES = 'img';
    url0001: any = environment.path_prod+'/api/getPerfilAccesoVisitante';
    url0002: any = environment.path_prod+'/api/usuario_miembro';
    url0003: any = environment.path_prod+'/api/getPerfilAccesoMiembros';
    url0004: any = environment.path_prod+'/api/getImgPerfil';
    url0005: any = environment.path_prod+'/api/getImgProd';
    url0006: any = environment.path_prod+'/api/getDepositoProd';
    url0007: any = environment.path_prod+'/api/getProd';
    url0008: any = environment.path_prod+'/api/getImgDLR';
    url0009: any = environment.path_prod+'/api/getUsuarioParametro';
    url0010: any = environment.path_prod+'/api/getDepositoProdDlr';
    url0011: any = environment.path_prod+'/api/getUsuarioParametroIdCloud';
    url0012: any = environment.path_prod+'/api/getUsuarioSwiper';
    url0013: any = environment.path_prod+'/api/getEventosLikeDislike';
    url0014: any = environment.path_prod+'/api/getFavoritos';
    url0015: any = environment.path_prod+'/api/getUsuarioChat';
    url0016: any = environment.path_prod+'/api/getUpdateUsuario';
    url0017: any = environment.path_prod+'/api/getPedidoCarrito';
    url0018: any = environment.path_prod+'/api/getPedidoCarritoActivo';
    url0019: any = environment.path_prod+'/api/getUpdateUsuarioOper';
    url0020: any = environment.path_prod+'/api/getPedidoCliente';
    url0021: any = environment.path_prod+'/api/getTdcUsuarioActiva';
    url0022: any = environment.path_prod+'/api/getFacturarPedido';
    url0023: any = environment.path_prod+'/api/getProductoPedido';
    url0024: any = environment.path_prod+'/api/getUpdateUsuarioVisit';
    url0025: any = environment.path_prod+'/api/getDefinicionSitema';
    url0026: any = environment.path_prod+'/api/getActualizarMembresia';
    url0027: any = environment.path_prod+'/api/getQuitarElementosPedido';
    url0028: any = environment.path_prod+'/api/getPagosPedido';
    url0029: any = environment.path_prod+'/api/getDepDlr';
    url0030: any = environment.path_prod+'/api/getTomarDeposito';
    url0031: any = environment.path_prod+'/api/getDepDlrTomado';
    url0032: any = environment.path_prod+'/api/getProductoOperador';
    url0033: any = environment.path_prod+'/api/getProductoPrecioOperador';
    url0034: any = environment.path_prod+'/api/getImgProducto';
    url0035: any = environment.path_prod+'/api/getDepositoProducto';
    url0036: any = environment.path_prod+'/api/getproductosSeller';
    url0037: any = environment.path_prod+'/api/getOperadorVendedor';
    url0038: any = environment.path_prod+'/api/getDepositoProdDlrBasic';
    url0039: any = environment.path_prod+'/api/getOfertaProducto';
    url0040: any = environment.path_prod+'/api/getProcessarOferta';
    url0041: any = environment.path_prod+'/api/getProductoActivoHome';
    url0042: any = environment.path_prod+'/api/getPedidoClienteOperador';
    url0043: any = environment.path_prod+'/api/getCheckoutProducto';
    url0044: any = environment.path_prod+'/api/getOrdenesProducto';
    url0045: any = environment.path_prod+'/api/getdeliveryProducto';
    url0046: any = environment.path_prod+'/api/getDelivery';
    url0047: any = environment.path_prod+'/api/getDeliveryOperador';
    url0048: any = environment.path_prod+'/api/getReceivedProducto';
    url0049: any = environment.path_prod+'/api/getBilleteraVendedor';
    url0050: any = environment.path_prod+'/api/getDeliveryCliente';
    url0051: any = environment.path_prod+'/api/getProductosOrdenActiva';
    url0052: any = environment.path_prod+'/api/getDataUsuario';
    url0053: any = environment.path_prod+'/api/getUsuarioSwiperSeller';
    url0054: any = environment.path_prod+'/api/getPrdrIdcloud';
    url0055: any = environment.path_prod+'/api/getDeseosUsuario';
    url0056: any = environment.path_prod+'/api/getQuitarDeseo';
    url0057: any = environment.path_prod+'/api/getDeseosActivos';
    url0058: any = environment.path_prod+'/api/getUpdateIdcloud';
    url0059: any = environment.path_prod+'/api/getQuitarFavoritos';
    url0060: any = environment.path_prod+'/api/getUsuarioContactoVs';
    url0061: any = environment.path_prod+'/api/getMsmUsuario';
    url0062: any = environment.path_prod+'/api/getCategoriaUsuario';
    url0063: any = environment.path_prod+'/api/getMsmUsuarioCont';
    url0064: any = environment.path_prod+'/api/getImgProductoGaleria';
    url0065: any = environment.path_prod+'/api/getGaleria';
    url0066: any = environment.path_prod+'/api/getQuitarGaleria';
    url0067: any = environment.path_prod+'/api/getQuitarProducto';
    url0068: any = environment.path_prod+'/api/getImgPerfilGaleria';
    url0069: any = environment.path_prod+'/api/getGaleriaPerfil';
    url0070: any = environment.path_prod+'/api/getQuitarGaleriaPerfil';
    url0071: any = environment.path_prod+'/api/getImgProductoVidGaleria';
    url0072: any = environment.path_prod+'/api/getVidGaleria';
    url0073: any = environment.path_prod+'/api/getCodStorage';
    url0074: any = environment.path_prod+'/api/getQuitarGaleriaVid';
    url0075: any = environment.path_prod+'/api/getVidGaleriaPerfil';
    url0076: any = environment.path_prod+'/api/getImgProductoVidGaleriaPerfil';
    url0077: any = environment.path_prod+'/api/getQuitarGaleriaVidPerfil';
    url0078: any = environment.path_prod+'/api/getVisitasUsuarios';
    url0079: any = environment.path_prod+'/api/getCorreoValido';
    url0080: any = environment.path_prod+'/api/getCodEnv';
    url0081: any = environment.path_prod+'/api/getCodEnvPass';
    url0082: any = environment.path_prod+'/api/getCodEnvPassUpdate';
    url0083: any = environment.path_prod+'/api/getBloqueoVendedor';
    url0084: any = environment.path_prod+'/api/getListaNegraUsuarioVendedor';
    url0085: any = environment.path_prod+'/api/getReportarVendedor';
    url0086: any = environment.path_prod+'/api/getUsuarioVendedorBloqueado';
    url0087: any = environment.path_prod+'/api/getRechazarProductoModerador';
    url0088: any = environment.path_prod+'/api/getQuitarDislike';
    url0089: any = environment.path_prod+'/api/getProdBuscar';
    url0090: any = environment.path_prod+'/api/updateUserPass';
      constructor(
        private postrest:HttpClient,
        private db:AngularFireStorage,
        private st:Storage
        ) {}

      loginhttp(xvalor:any):Observable<any>{
            let headers = new HttpHeaders().set('Content-Type','application/json');
            this.ruta = this.postrest.post<any>(this.url0001, xvalor,{headers});
            return this.ruta;
        }
      crearusuariohttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0002, xvalor,{headers});
          return this.ruta;
      }
      login_memberhttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0003, xvalor,{headers});
        return this.ruta;
         }
      imgPerfilhttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0004, xvalor,{headers});
          return this.ruta;
        }
      imgProdhttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0005, xvalor,{headers});
          return this.ruta;
        }
        getDepositoProdhttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0006, xvalor,{headers});
          return this.ruta;
        }
        getProdhttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0007,xvalor,{headers});
          return this.ruta;
        }
        getImgDLRttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0008,xvalor,{headers});
          return this.ruta;
        }
        getUsuarioParametrottp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0009,xvalor,{headers});
          return this.ruta;
        }
        getDepositoProdDlrhttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0010,xvalor,{headers});
          return this.ruta;
        }
        getUsuarioParametroIdCloudhttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0011,xvalor,{headers});
          return this.ruta;
        }

        getUsuarioSwiperhttp():Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0012,{headers});
          return this.ruta;
        }
        getEventosLikeDislikehttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0013, xvalor, {headers});
          return this.ruta;
        }
        getFavoritoshttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0014, xvalor,{headers});
          return this.ruta;
        }
        getUsuarioChathttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0015, xvalor,{headers});
          return this.ruta;
        }
        getUpdateUsuariohttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0016, xvalor,{headers});
          return this.ruta;
        }
        getPedidoCarritohttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0017, xvalor,{headers});
          return this.ruta;
        }
        getPedidoCarritoActivohttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0018, xvalor,{headers});
          return this.ruta;
        }
        getUpdateUsuarioOperhttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0019, xvalor,{headers});
          return this.ruta;
      }
      getPedidoClientehttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0020, xvalor,{headers});
        return this.ruta;
      }
      getTdcUsuarioActivahttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0021, xvalor,{headers});
        return this.ruta;
      }
      getFacturarPedidohttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0022, xvalor,{headers});
          return this.ruta;
      }
      getProductoPedidohttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0023, xvalor,{headers});
          return this.ruta;
      }
      getUpdateUsuarioVisithttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url0024, xvalor,{headers});
          return this.ruta;
      }
      getDefinicionSitemathttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0025, xvalor,{headers});
        return this.ruta;
      }
      getActualizarMembresiahttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0026, xvalor,{headers});
        return this.ruta;
      }
      getQuitarElementosPedidohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0027, xvalor,{headers});
        return this.ruta;
      }
      getPagosPedidohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0028, xvalor,{headers});
        return this.ruta;
      }
      getDepDlrhttp():Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0029,{headers});
        return this.ruta;
      }
      getTomarDepositohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0030, xvalor,{headers});
        return this.ruta;
      }
      getDepDlrTomadohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0031, xvalor,{headers});
        return this.ruta;
      }
      getProductoOperadorhttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0032, xvalor,{headers});
        return this.ruta;
      }
      getProductoPrecioOperadorhttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0033, xvalor,{headers});
        return this.ruta;
      }
      getImgProductohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0034, xvalor,{headers});
        return this.ruta;
      }
      getDepositoProductohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0035, xvalor,{headers});
        return this.ruta;
      }
      getproductosSellerhttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0036, xvalor,{headers});
        return this.ruta;
      }
      getOperadorVendedorhttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0037,xvalor,{headers});
        return this.ruta;
      }
      getDepositoProdDlrBasichttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0038,xvalor,{headers});
        return this.ruta;
      }
      getOfertaProductohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0039,xvalor,{headers});
        return this.ruta;
      }
      getProcessarOfertahttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0040,xvalor,{headers});
        return this.ruta;
      }
      
      getProductoActivoHomehttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0041,xvalor,{headers});
        return this.ruta;
      }
      getPedidoClienteOperadorhttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0042, xvalor,{headers});
        return this.ruta;
      }
      getCheckoutProductohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0043, xvalor,{headers});
        return this.ruta;
      }
      getOrdenesProductohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0044, xvalor,{headers});
        return this.ruta;
      }
      getdeliveryProductohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0045, xvalor,{headers});
        return this.ruta;
      }
      getDeliveryhttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0046, xvalor,{headers});
        return this.ruta;
      }
      getDeliveryOperadorhttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0047, xvalor,{headers});
        return this.ruta;
      }
      getReceivedProductohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0048, xvalor,{headers});
        return this.ruta;
      }
      getBilleteraVendedorhttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0049, xvalor,{headers});
        return this.ruta;
      }
      getDeliveryClientehttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0050, xvalor,{headers});
        return this.ruta;
      }
      getProductosOrdenActivahttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0051, xvalor,{headers});
        return this.ruta;
      }
      getDataUsuariohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0052, xvalor,{headers});
        return this.ruta;
      }
      getUsuarioSwiperSellerhttp():Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0053, {headers});
        return this.ruta;
      }
      getPrdrIdcloudhttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0054,xvalor,  {headers});
        return this.ruta;
      }

      getDeseosUsuariohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0055,xvalor,  {headers});
        return this.ruta;
      }

      getQuitarDeseohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0056,xvalor,  {headers});
        return this.ruta;
      }

      getDeseosActivoshttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0057,xvalor,  {headers});
        return this.ruta;
      }
      getUpdateIdcloudhttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0058, xvalor,{headers});
        return this.ruta;
     }
      getQuitarFavoritoshttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0059, xvalor,{headers});
        return this.ruta;
     }
        getUsuarioContactoVshttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0060, xvalor,{headers});
        return this.ruta;
     }
     getMsmUsuariohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0061, xvalor,{headers});
        return this.ruta;
     }
     getCategoriaUsuariohttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0062, xvalor,{headers});
        return this.ruta;
     }
     getMsmUsuarioConthttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url0063, xvalor,{headers});
        return this.ruta;
     }
     getImgProductoGaleriahttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0064, xvalor,{headers});
      return this.ruta;
    }
    getGaleriahttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0065, xvalor,{headers});
      return this.ruta;
    }
    getQuitarGaleriahttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0066, xvalor,{headers});
      return this.ruta;
    }
    getQuitarProductohttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0067, xvalor,{headers});
      return this.ruta;
    }

    getImgPerfilGaleriahttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0068, xvalor,{headers});
      return this.ruta;
    }
    getGaleriaPerfilhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0069, xvalor,{headers});
      return this.ruta;
    }
    getQuitarGaleriaPerfilhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0070, xvalor,{headers});
      return this.ruta;
    }
    getImgProductoVidGaleriahttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0071, xvalor,{headers});
      return this.ruta;
    }
    getVidGaleriahttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0072, xvalor,{headers});
      return this.ruta;
    }
    getCodStoragehttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0073, xvalor,{headers});
      return this.ruta;
    }
    getQuitarGaleriaVidhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0074, xvalor,{headers});
      return this.ruta;
    }
    getVidGaleriaPerfilhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0075, xvalor,{headers});
      return this.ruta;
    }
    getImgProductoVidGaleriaPerfilhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0076, xvalor,{headers});
      return this.ruta;
    }
    getQuitarGaleriaVidPerfilhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0077, xvalor,{headers});
      return this.ruta;
    }
    getVisitasUsuarioshttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0078,{headers});
      return this.ruta;
    }
    getCorreoValidohttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0079, xvalor,{headers});
      return this.ruta;
    }
    getCodEnvhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0080, xvalor,{headers});
      return this.ruta;
    }
    getCodEnvPasshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0081, xvalor,{headers});
      return this.ruta;
    }
    getCodEnvPassUpdatehttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0082, xvalor,{headers});
      return this.ruta;
    }
    getBloqueoVendedorhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0083, xvalor,{headers});
      return this.ruta;
    }
    getListaNegraUsuarioVendedorhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0084, xvalor,{headers});
      return this.ruta;
    }
    getReportarVendedorhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0085, xvalor,{headers});
      return this.ruta;
    }
    getUsuarioVendedorBloqueadohttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0086, xvalor,{headers});
      return this.ruta;
    }
    getRechazarProductoModeradorhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0087, xvalor,{headers});
      return this.ruta;
    }
    getQuitarDislikehttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0088, xvalor, {headers});
      return this.ruta;
    }
    getProdBuscarttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0089, xvalor, {headers});
      return this.ruta;
    }
    updateUserPass(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url0090, xvalor, {headers});
      return this.ruta;
    }
}
