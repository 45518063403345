import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import * as moment from 'moment';
import { AuthGuardServicesService } from 'src/app/services/auth-guard-services.service';
import { skServices } from 'src/app/services/sk.services.service';
import { StoreService } from 'src/app/services/store.service';
import { UiKitController } from 'src/app/services/uikitcontroller.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { Productos, Usuario } from 'src/models';
import { SwiperOptions } from 'swiper';

import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform, LoadedImage } from 'ngx-image-cropper';


@Component({
  selector: 'app-form-galeria',
  templateUrl: './form-galeria.component.html',
  styleUrls: ['./form-galeria.component.scss'],
})
export class FormGaleriaComponent implements OnInit {
  @Input() data: any;
  galeria:any;
  path:any;
  usuario: Usuario;
  NuevaImagen:any = '';
  NuevoArchivo:any = '';
  validacion: any;
  objectURL:any = 'https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png';
  croppedImage:any = 'https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png';

  imageChangedEvent: any = '';
  
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  lg:any = localStorage.getItem('lg');

  // variables para experiencia de usuario

  PermitirNuevoProducto = false;
  loading: any;
  img:any = '';
  img_count:any = [];
  img_prod:any = '';
  nombre_img:any = '';
  param_img:any = '';
  img_prod_all:any = '';
  forma: FormGroup;
  forma2: FormGroup;
  rows:any = [];
  indexCard: any;

  config2: SwiperOptions = {
    initialSlide: 1,
    direction: 'vertical',
    slidesPerView: 1,
    spaceBetween: 10,
    effect: 'slide',
  };

  Images2: any = [
    {
      activate: 'like',
    },
    {
      activate: 'null',
    },
    {
      activate: 'dislike',
    },
  ];

  config: SwiperOptions = {
    autoplay: true,
    keyboard: true,
    slidesPerView: 1,
    pagination:true,
    navigation:true,
    spaceBetween: 10,
    scrollbar: { draggable: true },
  };

  constructor(
    public modalController: ModalController,
    public userProductControl: UserService,
    public firestoreService: StoreService,
    public loadingController: LoadingController,
    public toastController: ToastController,
    public fb: FormBuilder,
    public userControl: AuthGuardServicesService,
    private loadindControl: LoadingController,
    private util: UiKitController,
    private store: StoreService,
    private skservice: skServices,
  ) {}
  get title(): any{
    return this.forma.get('title')
  }

  ngOnInit() {
    this.galeria = this.data.galeria;
    console.log(this.data);
    this.img_prod_all = this.data.cod_img;
   
      this.listarGaleria(this.data);
    

    this.usuario = this.userControl.Usuario

    this.path = `Productos/`
    this.forma2 = this.fb.group({
      img: [''],
      alt:''
    })
  }

  ngOnDestroy() {
    URL.revokeObjectURL(this.objectURL);
  }

  async GuardarImagen(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.NuevoArchivo = event.target.files[0];
      const reader = new FileReader();
      reader.onload = ((image) =>{
        this.NuevaImagen = image.target.result as string;
        this.forma.patchValue({src:image.target.result})
      });
      reader.readAsDataURL(event.target.files[0])
    }
  }
  async getimgprod(objeto:any) {
    try {
      await this.util.loading();
      this.nombre_img = objeto.target.files[0];
      this.img_count.push(this.img_prod);

      this.objectURL = URL.createObjectURL(objeto.target.files[0]);
      this.imageChangedEvent = objeto;
      this.croppedImage = objeto;
      console.log(this.objectURL);
      
    } catch (error) {
      
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async listarGaleria(xvalor:any){
    try {
      this.util.loading();
      console.log(xvalor);
      this.rows = [];
      var data = {
        cod_usuario:xvalor.cod_usuario,
      };
     const response =  await this.skservice.getGaleriaPerfilhttp(data).toPromise();
    console.log(response);
     this.rows = [...response.img];
    } catch (error) {
     
      console.log(error);
    }
  }

  async imagenProdGaleria() {
    try {
      await this.util.loading();
      if (this.rows.length === 10) {
        await this.util.ToastWarning('You can only upload 10 images for now');
        return;
      }
      
      if (this.img_prod !== '' && this.nombre_img.name.length <= parseInt(environment.nombre_img) &&  this.nombre_img.name.length >= 1 ) {
        console.log(this.img_prod );
                const response =  await this.store.CargarImgCrop(this.img_prod, this.nombre_img, this.param_img);
                console.log(response);
                var data = {
                  cod_usuario: localStorage.getItem('cod_usuario'),
                  cod_img: response,
                }
                console.log(data);
                const response_ = await this.skservice.getImgPerfilGaleriahttp(data).toPromise();
                await this.util.ToastSuccess(response_.message);
              this.listarGaleria(data);
      
      }else{
        
        if (this.nombre_img === '') {
          await this.util.ToastWarning('You must upload an image to continue');
        }
        if (this.nombre_img.name.length > parseInt(environment.nombre_img)) {
          await this.util.ToastWarning('Image name must be less than '+parseInt(environment.nombre_img)+' characters');
        }
        if (this.nombre_img.name.length < 1) {
          
          await this.util.ToastWarning('You can not upload unnamed images');
        }
        
      }
      
    } catch (error) {
      console.log(error);
      await this.util.ToastWarning(error.error.message);
    }
  }
  async eliminarProdGaleria(xvalor:any) {
    try {
      await this.util.loading();
      const response_ =  await this.store.QuitarImgPerfilProd(xvalor.cod_img);
      console.log(response_);
      var data = {
        cod_usuario: localStorage.getItem('cod_usuario'),
        cod_img: xvalor.cod_img,
      }
      
      const response = await this.skservice.getQuitarGaleriaPerfilhttp(data).toPromise();
      console.log(response);
      await this.util.ToastSuccess(response.message);
      
      this.listarGaleria(this.data);
    } catch (error) {
      console.log(error);
    }
  }
 
  async dismissModal() {
    this.modalController.dismiss();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
}

imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
   
    console.log(event);
    console.log('Pasa por aca');
    this.param_img = event.cropperPosition.x1+event.imagePosition.x1
    this.img_prod = base64ToFile(event.base64);
   
}

imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
}

cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
}

loadImageFailed() {
    console.log('Load failed');
}

rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
}

rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
}

private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
    };
}


flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
}

flipVertical() {
    this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
    };
}

resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
}

zoomOut() {
    this.scale -= .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}

zoomIn() {
    this.scale += .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}

toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
}

updateRotation() {
    this.transform = {
        ...this.transform,
        rotate: this.rotation
    };
}

}
